import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import StackAdsBrands from "../publishers/StackAdsBrands";
import HeroSectionExtraHeading from "../HeroSectionExtraHeading";
import { brandColor2, white } from "../styles/Colors";
import HeroSection from "../HeroSection";
import CTALink from "../styles/CTALink";
import TextHighlight from "../styles/TextHighlight";
import HeroTestimonial from "../HeroTestimonial";
import { Headline32 } from "../styles/Headings";
import ColorBrands from "../brands/ColorBrands";
import HeroMetrics from "../HeroMetrics";
import ShopStories from "../brands/ShopStories";
import HeroTestimonials from "../HeroTestimonials";
import HeroBlueCTA from "../HeroBlueCTA";
import ContentAnimation from "../ContentAnimation";

import shopsDesktop from "../../images/61-brands-shop-desktop.png";
import shopsMedia from "../../images/61-brands-shop-media.png";
import imageTestimonialOne from "../../images/57-testimonial-francois-vandenheste.png";
import imageTestimonialTwo from "../../images/57-testimonial-tiago-martins.png";
import imageTestimonialThree from "../../images/57-testimonial-max-edwards.png";
import HorizontalPhonesAnimation from "../HorizontalPhonesAnimation";

const shops01Images = {
  desktop: {
    src: shopsDesktop,
    width: 927,
    height: 734
  },
  mobile: {
    src: shopsMedia,
    width: 375,
    height: 431
  }
};

const testimonialData = [
  {
    image: imageTestimonialOne,
    description: "Francois Vandenheste @ KLIKR Smart Remote",
    content:
      "“Since launching KLIKR on StackCommerce’s platform, our product has been featured across numerous top blogs and websites! It’s been an amazing PR campaign.”"
  },
  {
    image: imageTestimonialTwo,
    description: "Tiago Martins @ CodePlace",
    content:
      '"We pretty much launched our business on Stackcommerce. They were the perfect distribution channel for our coding school. Great company, awesome people, unbelievable results." '
  },
  {
    image: imageTestimonialThree,
    description: "Max Edwards @ Corel",
    content:
      "“StackCommerce has been a valuable partner to Corel for many years. They are able to tap into an audience that our traditional marketing vehicles simply cannot address today.”"
  }
];

const BrandsShopsDefer = () => {
  const images = useStaticQuery(graphql`
    query {
      contentOne: file(relativePath: { eq: "58-brands-shop-content-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contentTwo: file(relativePath: { eq: "58-brands-shop-content-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      logoTestimonial: file(
        relativePath: { eq: "56-testimonial-yaasa-studios.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "56-testimonial-brands-shop-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      emailOne: file(relativePath: { eq: "59-brands-shop-email-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      emailTwo: file(relativePath: { eq: "59-brands-shop-email-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      emailThree: file(relativePath: { eq: "59-brands-shop-email-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marketingOne: file(
        relativePath: { eq: "60-brands-shop-marketing-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marketingTwo: file(
        relativePath: { eq: "60-brands-shop-marketing-2.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marketingThree: file(
        relativePath: { eq: "60-brands-shop-marketing-3.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <StackAdsBrands />

      <HeroSectionExtraHeading
        heading="How Stack Does"
        outline="Commerce"
        top="01"
        title="Shops"
        backgroundColor={brandColor2}
        images={shops01Images}
        alt={true}
        theme={{
          backgroundColor: white,
          titleMarginDesktop: "306px",
          titleWidth: "200px",
          imageMarginMedia: "-12% 0 0",
          imageLeft: "45%",
          imageTop: "initial",
          imageBottom: "0",
          paddingMedia: "91px 0 0"
        }}
      >
        Your product goes live across hundreds of branded publisher marketplaces
        in a click.
      </HeroSectionExtraHeading>

      <HeroSection
        top="02"
        title="Content"
        backgroundColor={brandColor2}
        alt={true}
        reverse={true}
        theme={{
          paddingMedia: "93px 0 0"
        }}
        displayComponent={<ContentAnimation images={images} />}
      >
        Get discovered through affiliate content written by Stack’s{" "}
        <CTALink
          variant="white"
          bold
          underlined
          attrs={{ to: "/publishers/content-studio/" }}
        >
          Content Studio
        </CTALink>{" "}
        for top media sites.
      </HeroSection>

      <HeroSection
        top="03"
        title="Email"
        theme={{
          contentMarginBottom: "54px",
          paddingDesktop: "200px 0 174px"
        }}
        displayComponent={
          <HorizontalPhonesAnimation
            forceActive={true}
            theme={{
              left: true,
              width: "450px",
              middleTransform: "calc(57% + 31px)",
              rightTransform: "calc(127% + 32px)",
              positionLarge: "translateX(-48%) translateY(-150%)",
              positionMedium: "translateX(-48%) translateY(-150%)",
              positionSmall: "translateX(-48%) translateY(-150%)",
              rightTop: "11px",
              middleTop: "5px",
              rightMediaWidth: "214%",
              rightMediaTop: "4%"
            }}
            images={{
              one: images.emailOne,
              two: images.emailTwo,
              three: images.emailThree
            }}
          />
        }
      >
        Get included in emails sent to <br />
        <TextHighlight theme={{ background: white }}>
          4m+ subscribers
        </TextHighlight>{" "}
        daily.
      </HeroSection>

      <HeroSection
        top="04"
        title="Marketing"
        reverse={true}
        theme={{
          contentMarginBottom: "54px",
          paddingDesktop: "200px 0 174px"
        }}
        displayComponent={
          <HorizontalPhonesAnimation
            theme={{
              width: "450px",
              middleTransform: "calc(57% + 31px)",
              rightTransform: "calc(127% + 32px)",
              positionLarge: "translateX(-75%) translateY(-150%)",
              positionMedium: "translateX(-80%) translateY(-150%)",
              positionSmall: "translateX(-80%) translateY(-150%)",
              rightTop: "11px",
              middleTop: "5px",
              rightMediaWidth: "224%",
              rightMediaTop: "-4%"
            }}
            images={{
              one: images.marketingOne,
              two: images.marketingTwo,
              three: images.marketingThree
            }}
          />
        }
      >
        We leverage paid ads, push notifications, and organic social media to
        scale your campaign.
      </HeroSection>

      <HeroTestimonial
        media={images}
        author="Johannes Sauer | CEO @ Yaasa Studios"
      >
        <Headline32 theme={{ color: white, margins: "18px 0" }}>
          StackCommerce has not only helped us to sell a lot of our Infinity
          Blankets, but more importantly raise brand awareness for Yaasa.
        </Headline32>
      </HeroTestimonial>

      <ColorBrands />

      <HeroMetrics
        number="100"
        prefix="$"
        suffix="M+"
        description="Earned by Merchants on the StackCommerce Platform"
        theme={{ width: "500px" }}
      />

      <ShopStories />

      <HeroTestimonials data={testimonialData} />

      <HeroBlueCTA
        title="LET’S SELL"
        outline="TOGETHER"
        description="See how Stack can help supercharge your revenue and grow your brand."
        link={{ to: "/contact/?smopt=brand", state: { from: "brand" }, as: Link }}
        linkLabel="Get A Demo"
      />
    </>
  );
};

export default BrandsShopsDefer;
