import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Image from "../Image";
import Container from "../styles/Container";
import { black1 } from "../styles/Colors";
import { mediaDesktop, mediaMedium } from "../styles/variables";
import AnimationWrapper from "../AnimationWrapper";
import { MoveInBottom } from "../styles/animations";

const Background = styled.div`
  background-color: ${black1};
  padding: 96px 0 95px;

  @media (${mediaMedium}) {
    padding: 160px 0;
  }
`;

const Item = styled.div`
  grid-column: span 1;
  order: ${(props) => props.order ?? 1};

  @media (${mediaMedium}) {
    grid-column: span 12;
    order: 1 !important;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  grid-row-gap: 58px;
  grid-column-gap: 58px;
  grid-template-columns: repeat(3, 1fr);

  @media (${mediaMedium}) {
    grid-column-gap: 0;
    grid-row-gap: 98px;
    grid-template-columns: repeat(60, 1fr);
    padding: 0 16px;
  }

  @media (${mediaDesktop}) {
    grid-column-gap: 18px;
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
    overflow: visible !important;
  }

  @media (max-width: 829px) {
    ${Item}:nth-child(7) {
      order: 10;
    }

    ${Item}:nth-child(10) {
      order: 7;
    }

    ${Item}:nth-child(3n) {
      .gatsby-image-wrapper {
        margin-right: 0;
      }
    }

    ${Item}:nth-child(3n-2) {
      .gatsby-image-wrapper {
        margin-left: 0;
      }
    }
  }

  @media (${mediaMedium}) {
    ${Item}:nth-child(1),
    ${Item}:nth-child(6),
    ${Item}:nth-child(11),
    ${Item}:nth-child(15),
    ${Item}:nth-child(20) {
      text-align: left;

      .gatsby-image-wrapper {
        margin: 0 auto 0 0;
      }
    }

    ${Item}:nth-child(5),
    ${Item}:nth-child(10),
    ${Item}:nth-child(14),
    ${Item}:nth-child(19),
    ${Item}:nth-child(22),
    ${Item}:nth-child(23),
    ${Item}:nth-child(24) {
      text-align: right;

      .gatsby-image-wrapper {
        margin: 0 0 0 auto;
      }
    }

    ${Item}:nth-child(21),
    ${Item}:nth-child(22) {
      grid-column: span 14;
    }

    ${Item}:nth-child(23),
    ${Item}:nth-child(24) {
      grid-column: span 10;
    }

    ${Item}:nth-child(n + 11):nth-child(-n + 14) {
      grid-column: span 15;
    }
  }
`;

const StackAdsBrandsSection = ({ data }) => {
  return (
    <Background>
      <Container>
        <Grid>
          {data &&
            Object.keys(data).map((item, key) => (
              <Item key={item} order={key + 1}>
                <AnimationWrapper component={MoveInBottom}>
                  <Image fluid={data[item].childImageSharp.fluid} />
                </AnimationWrapper>
              </Item>
            ))}
        </Grid>
      </Container>
    </Background>
  );
};

const StackAdsBrands = () => {
  const data = useStaticQuery(graphql`
    query {
      skullcandy: file(relativePath: { eq: "29-skullcandy.png" }) {
        childImageSharp {
          fluid(maxWidth: 156, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      dji: file(relativePath: { eq: "29-dji.png" }) {
        childImageSharp {
          fluid(maxWidth: 65, maxHeight: 37, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      hulu: file(relativePath: { eq: "29-hulu.png" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marshall: file(relativePath: { eq: "29-marshall.png" }) {
        childImageSharp {
          fluid(maxWidth: 155, maxHeight: 40, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      urbanears: file(relativePath: { eq: "29-urbanears.png" }) {
        childImageSharp {
          fluid(maxWidth: 169, maxHeight: 22, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      microsoft: file(relativePath: { eq: "29-microsoft.png" }) {
        childImageSharp {
          fluid(maxWidth: 165, maxHeight: 36, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      apple: file(relativePath: { eq: "29-apple.png" }) {
        childImageSharp {
          fluid(maxWidth: 41, maxHeight: 48, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      hp: file(relativePath: { eq: "29-hp.png" }) {
        childImageSharp {
          fluid(maxWidth: 58, maxHeight: 56, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      lg: file(relativePath: { eq: "29-lg.png" }) {
        childImageSharp {
          fluid(maxWidth: 84, maxHeight: 37, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      samsung: file(relativePath: { eq: "29-samsung.png" }) {
        childImageSharp {
          fluid(maxWidth: 170, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      sennheiser: file(relativePath: { eq: "29-sennheiser.png" }) {
        childImageSharp {
          fluid(maxWidth: 231, maxHeight: 24, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bangOlufsen: file(relativePath: { eq: "29-bang-olufsen.png" }) {
        childImageSharp {
          fluid(maxWidth: 212, maxHeight: 18, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      sony: file(relativePath: { eq: "29-sony.png" }) {
        childImageSharp {
          fluid(maxWidth: 138, maxHeight: 24, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      altecLansing: file(relativePath: { eq: "29-altecLansing.png" }) {
        childImageSharp {
          fluid(maxWidth: 141, maxHeight: 37, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      flowerscom: file(relativePath: { eq: "29-flowerscom.png" }) {
        childImageSharp {
          fluid(maxWidth: 140, maxHeight: 62, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wolfgangPuck: file(relativePath: { eq: "29-wolfgang-puck.png" }) {
        childImageSharp {
          fluid(maxWidth: 165, maxHeight: 69, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marthaStewart: file(relativePath: { eq: "29-martha-stewart.png" }) {
        childImageSharp {
          fluid(maxWidth: 105, maxHeight: 41, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      rosettaStone: file(relativePath: { eq: "29-rosetta-stone.png" }) {
        childImageSharp {
          fluid(maxWidth: 122, maxHeight: 53, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      winc: file(relativePath: { eq: "29-winc.png" }) {
        childImageSharp {
          fluid(maxWidth: 102, maxHeight: 31, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      shutterstock: file(relativePath: { eq: "29-shutterstock.png" }) {
        childImageSharp {
          fluid(maxWidth: 170, maxHeight: 24, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      turbotax: file(relativePath: { eq: "29-turbotax.png" }) {
        childImageSharp {
          fluid(maxWidth: 156, maxHeight: 35, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      audible: file(relativePath: { eq: "29-audible.png" }) {
        childImageSharp {
          fluid(maxWidth: 144, maxHeight: 59, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      adobe: file(relativePath: { eq: "29-adobe.png" }) {
        childImageSharp {
          fluid(maxWidth: 54, maxHeight: 62, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      nordVPN: file(relativePath: { eq: "29-nord-vpn.png" }) {
        childImageSharp {
          fluid(maxWidth: 92, maxHeight: 75, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <StackAdsBrandsSection data={data} />;
};

export default StackAdsBrands;
