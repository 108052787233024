import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Image from "../Image";
import Container from "../styles/Container";
import { white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import { Headline64 } from "../styles/Headings";
import AnimationWrapper from "../AnimationWrapper";
import { MoveInBottom } from "../styles/animations";

const Background = styled.div`
  background-color: ${white};
  padding: 92px 0 96px;

  @media (${mediaMedium}) {
    padding: 152px 0 160px;
  }
`;

const Item = styled.div`
  grid-column-end: span 1;
  order: ${(props) => props.order ?? 1};

  @media (${mediaMedium}) {
    grid-column-end: span 10;
    order: 1 !important;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  grid-row-gap: 58px;
  grid-column-gap: 58px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 829px) {
    ${Item}:nth-child(3n) {
      .gatsby-image-wrapper {
        margin-right: 0;
      }
    }

    ${Item}:nth-child(8) {
      .gatsby-image-wrapper {
        max-width: 50px !important;
      }
    }

    ${Item}:nth-child(3n-2) {
      .gatsby-image-wrapper {
        margin-left: 0;
      }
    }
  }

  @media (${mediaMedium}) {
    grid-row-gap: 84px;
    grid-column-gap: 0;
    padding: 0 16px;
    grid-template-columns: repeat(60, 1fr);

    ${Item} {
      width: 85%;
    }

    ${Item}:nth-child(6n) {
      .gatsby-image-wrapper {
        margin-right: 0;
      }
    }

    ${Item}:nth-child(6n+1) {
      .gatsby-image-wrapper {
        margin-left: 0;
      }
    }
  }

  @media (min-width: 1160px) {
    grid-column-gap: 18px;

    ${Item} {
      width: 100%;
    }
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
    overflow: visible !important;
  }
`;

const Title = styled.div`
  text-align: center;
  max-width: 327px;
  margin: 0 auto 88px;

  @media (${mediaMedium}) {
    max-width: 820px;
    margin-bottom: 110px;
  }
`;

const StackAdsBrandsSection = ({ data }) => {
  return (
    <Background>
      <Container>
        <Title>
          <Headline64 as="h3">Reach Top Audiences on the Web</Headline64>
        </Title>

        <Grid>
          {data &&
            Object.keys(data).map((item, key) => (
              <Item key={item} order={key + 1}>
                <AnimationWrapper component={MoveInBottom}>
                  <Image fluid={data[item].childImageSharp.fluid} />
                </AnimationWrapper>
              </Item>
            ))}
        </Grid>
      </Container>
    </Background>
  );
};

const ColorBrands = () => {
  const data = useStaticQuery(graphql`
    query {
      mashable: file(relativePath: { eq: "63-mashable.png" }) {
        childImageSharp {
          fluid(maxWidth: 131, maxHeight: 24, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      engaget: file(relativePath: { eq: "63-engadget.png" }) {
        childImageSharp {
          fluid(maxWidth: 137, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      macworld: file(relativePath: { eq: "63-macworld.png" }) {
        childImageSharp {
          fluid(maxWidth: 139, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      pcworld: file(relativePath: { eq: "63-pcworld.png" }) {
        childImageSharp {
          fluid(maxWidth: 126, maxHeight: 28, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cnn: file(relativePath: { eq: "63-cnn.png" }) {
        childImageSharp {
          fluid(maxWidth: 77, maxHeight: 36, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      yahoo: file(relativePath: { eq: "63-yahoo.png" }) {
        childImageSharp {
          fluid(maxWidth: 119, maxHeight: 35, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      lifehacker: file(relativePath: { eq: "63-lifehacker.png" }) {
        childImageSharp {
          fluid(maxWidth: 133, maxHeight: 33, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cnet: file(relativePath: { eq: "63-cnet.png" }) {
        childImageSharp {
          fluid(maxWidth: 78, maxHeight: 78, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mac9to5: file(relativePath: { eq: "63-9t05mac.png" }) {
        childImageSharp {
          fluid(maxWidth: 147, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      androidAuthority: file(relativePath: { eq: "63-android-authority.png" }) {
        childImageSharp {
          fluid(maxWidth: 123, maxHeight: 46, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      huffpost: file(relativePath: { eq: "63-huffpost.png" }) {
        childImageSharp {
          fluid(maxWidth: 146, maxHeight: 18, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      aol: file(relativePath: { eq: "63-aol.png" }) {
        childImageSharp {
          fluid(maxWidth: 86, maxHeight: 35, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cracked: file(relativePath: { eq: "63-cracked.png" }) {
        childImageSharp {
          fluid(maxWidth: 118, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      gizmodo: file(relativePath: { eq: "63-gizmodo.png" }) {
        childImageSharp {
          fluid(maxWidth: 140, maxHeight: 20, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cultOfMac: file(relativePath: { eq: "63-cult-of-mac.png" }) {
        childImageSharp {
          fluid(maxWidth: 141, maxHeight: 21, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      xdaDevelopers: file(relativePath: { eq: "63-xda-developers.png" }) {
        childImageSharp {
          fluid(maxWidth: 152, maxHeight: 22, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      tmz: file(relativePath: { eq: "63-tmz.png" }) {
        childImageSharp {
          fluid(maxWidth: 81, maxHeight: 33, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      entrepreneur: file(relativePath: { eq: "63-entrepreneur.png" }) {
        childImageSharp {
          fluid(maxWidth: 130, maxHeight: 28, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      digg: file(relativePath: { eq: "63-digg.png" }) {
        childImageSharp {
          fluid(maxWidth: 71, maxHeight: 44, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      goodMorningAmerica: file(
        relativePath: { eq: "63-good-morning-america.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 123, maxHeight: 60, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      iMore: file(relativePath: { eq: "63-imore.png" }) {
        childImageSharp {
          fluid(maxWidth: 124, maxHeight: 27, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      androidCentral: file(relativePath: { eq: "63-android-central.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, maxHeight: 18, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      nyPost: file(relativePath: { eq: "63-ny-post.png" }) {
        childImageSharp {
          fluid(maxWidth: 158, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      theChive: file(relativePath: { eq: "63-chive.png" }) {
        childImageSharp {
          fluid(maxWidth: 118, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <StackAdsBrandsSection data={data} />;
};

export default ColorBrands;
