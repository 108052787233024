import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HeroStories from "../HeroStories";

const data = [
  {
    title: "Genius Pack gets 58+ mentions in articles on top publishers",
    topic: "consumer products",
    link: "/slides/StackCommerce_Case_Study_Genius_Pack.pdf"
  },
  {
    title: "Chris Haroun gains over 22,000 new students",
    topic: "elearning",
    link: "/slides/StackCommerce_Case_Study_Chris_Haroun.pdf"
  },
  {
    title: "Babbel drives over $4m in sales",
    topic: "apps & software",
    link: "/slides/StackCommerce_Case_Study_Babbel.pdf"
  }
];

const ShopStories = () => {
  const images = useStaticQuery(graphql`
    query {
      image0: file(
        relativePath: { eq: "64-success-story-consumer-products.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image1: file(relativePath: { eq: "64-success-story-elearning.png" }) {
        childImageSharp {
          fluid(maxWidth: 720, maxHeight: 1096, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(relativePath: { eq: "64-success-story-apps-software.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <HeroStories data={data} images={images} />;
};

export default ShopStories;
